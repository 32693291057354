// Register
export const FETCH_USER_REGISTER_DETAILS = "FETCH_USER_REGISTER_DETAILS";
export const FETCH_USER_REGISTER_DETAILS_SUCCESS =
  "FETCH_USER_REGISTER_DETAILS_SUCCESS";
export const FETCH_USER_REGISTER_DETAILS_FAILURE =
  "FETCH_USER_REGISTER_DETAILS_FAILURE";

//Login
export const FETCH_USER_LOGIN_DETAILS = "FETCH_USER_LOGIN_DETAILS";
export const FETCH_USER_LOGIN_DETAILS_SUCCESS =
  "FETCH_USER_LOGIN_DETAILS_SUCCESS";
export const FETCH_USER_LOGIN_DETAILS_FAILURE =
  "FETCH_USER_LOGIN_DETAILS_FAILURE";

//User details
export const FETCH_USER_DETAILS = "FETCH_USER_DETAILS";
export const FETCH_USER_DETAILS_SUCCESS = "FETCH_USER_DETAILS_SUCCESS";
export const FETCH_USER_DETAILS_FAILURE = "FETCH_USER_DETAILS_FAILURE";

// common
export const FETCH_COMMON_DETAILS = "FETCH_COMMON_DETAILS";
export const FETCH_COMMON_DETAILS_SUCCESS = "FETCH_COMMON_DETAILS_SUCCESS";
export const FETCH_COMMON_DETAILS_FAILURE = "FETCH_COMMON_DETAILS_FAILURE";

// store
export const FETCH_STORE_LIST = "FETCH_STORE_LIST";
export const FETCH_STORE_LIST_SUCCESS = "FETCH_STORE_LIST_SUCCESS";
export const FETCH_STORE_LIST_FAILURE = "FETCH_STORE_LIST_FAILURE";

export const SELECT_STORE_TYPE = "SELECT_STORE_TYPE";
export const SELECT_CURRENT_STORE = "SELECT_CURRENT_STORE";

// homepage
export const FETCH_BANNER_AND_TESTIMONIALS_DATA =
  "FETCH_BANNER_AND_TESTIMONIALS_DATA";
export const FETCH_BANNER_AND_TESTIMONIALS_DATA_SUCCESS =
  "FETCH_BANNER_AND_TESTIMONIALS_DATA_SUCCESS";
export const FETCH_BANNER_AND_TESTIMONIALS_DATA_FAILURE =
  "FETCH_BANNER_AND_TESTIMONIALS_DATA_FAILURE";

export const FETCH_OFFER_LIST_WITH_PRODUCTS = "FETCH_OFFER_LIST_WITH_PRODUCTS";
export const FETCH_OFFER_LIST_WITH_PRODUCTS_SUCCESS =
  "FETCH_OFFER_LIST_WITH_PRODUCTS_SUCCESS";
export const FETCH_OFFER_LIST_WITH_PRODUCTS_FAILURE =
  "FETCH_OFFER_LIST_WITH_PRODUCTS_FAILURE";

export const FETCH_PREVIOUS_BUYS_PRODUCT_LIST =
  "FETCH_PREVIOUS_BUYS_PRODUCT_LIST";
export const FETCH_PREVIOUS_BUYS_PRODUCT_LIST_SUCCESS =
  "FETCH_PREVIOUS_BUYS_PRODUCT_LIST_SUCCESS";
export const FFETCH_PREVIOUS_BUYS_PRODUCT_LIST_FAILURE =
  "FFETCH_PREVIOUS_BUYS_PRODUCT_LIST_FAILURE";

export const FETCH_PRODUCTS_DATA = "FETCH_PRODUCTS_DATA";
export const FETCH_PRODUCTS_DATA_SUCCESS = "FETCH_PRODUCTS_DATA_SUCCESS";
export const FETCH_PRODUCTS_DATA_FAILURE = "FETCH_PRODUCTS_DATA_FAILURE";

export const FETCH_CART_WISHLIST_DETAILS_WITH_ITEMS =
  "FETCH_CART_WISHLIST_DETAILS_WITH_ITEMS";
export const FETCH_CART_WISHLIST_DETAILS_WITH_ITEMS_SUCCESS =
  "FETCH_CART_WISHLIST_DETAILS_WITH_ITEMS_SUCCESS";
export const FETCH_CART_WISHLIST_DETAILS_WITH_ITEMS_FAILURE =
  "FETCH_CART_WISHLIST_DETAILS_WITH_ITEMS_FAILURE";

export const FETCH_PRODUCT_LIST_BY_DEPARTMENT =
  "FETCH_PRODUCT_LIST_BY_DEPARTMENT";
export const FETCH_PRODUCT_LIST_BY_DEPARTMENT_SUCCESS =
  "FETCH_PRODUCT_LIST_BY_DEPARTMENT_SUCCESS";
export const FETCH_PRODUCT_LIST_BY_DEPARTMENT_FAILURE =
  "FETCH_PRODUCT_LIST_BY_DEPARTMENT_FAILURE";

// Hot Offer data fetch
export const FETCH_HOT_OFFERS_DATA = "FETCH_HOT_OFFERS_DATA";
export const FETCH_HOT_OFFERS_DATA_SUCCESS = "FETCH_HOT_OFFERS_DATA_SUCCESS";
export const FETCH_HOT_OFFERS_DATA_FAILURE = "FETCH_HOT_OFFERS_DATA_FAILURE";

// Product filter data fetch
export const FETCH_FILTER_OPTIONS_LIST = "FETCH_FILTER_OPTIONS_LIST";
export const FETCH_FILTER_OPTIONS_LIST_SUCCESS =
  "FETCH_FILTER_OPTIONS_LIST_SUCCESS";
export const FETCH_FILTER_OPTIONS_LIST_FAILURE =
  "FETCH_FILTER_OPTIONS_LIST_FAILURE";

export const UPDATE_SORT_BY_VALUE = "UPDATE_SORT_BY_VALUE";
export const UPDATE_PAGE_LIMIT = "UPDATE_PAGE_LIMIT";
export const UPDATE_IFT_CHECK_LIST = "UPDATE_IFT_CHECK_LIST";
export const UPDATE_FROM_LIST = "UPDATE_FROM_LIST";
export const UPDATE_PAST_ORDER_LIST = "UPDATE_PAST_ORDER_LIST";

export const UPDATE_ACTIVE_PAGE = "UPDATE_ACTIVE_PAGE";
export const UPDATE_PRICE_VALUE = "UPDATE_PRICE_VALUE";

// Department tree view
export const FETCH_DEPARTMENT_TREE = "FETCH_DEPARTMENT_TREE";
export const FETCH_DEPARTMENT_TREE_SUCCESS = "FETCH_DEPARTMENT_TREE_SUCCESS";
export const FETCH_DEPARTMENT_TREE_FAILURE = "FETCH_DEPARTMENT_TREE_FAILURE";

export const SEARCH_DEPARTMENT_TREE = "SEARCH_DEPARTMENT_TREE";
export const SEARCH_DEPARTMENT_TREE_SUCCESS = "SEARCH_DEPARTMENT_TREE_SUCCESS";
export const SEARCH_DEPARTMENT_TREE_FAILURE = "SEARCH_DEPARTMENT_TREE_FAILURE";

export const UPDATE_NODE_FUNCTIONAL_TEXT = "UPDATE_NODE_FUNCTIONAL_TEXT";
export const UPDATE_NODE_FUNCTIONAL_LEVEL = "UPDATE_NODE_FUNCTIONAL_LEVEL";

// About us view
export const FETCH_ABOUTUS_DATA = "FETCH_ABOUTUS_DATA";
export const FETCH_ABOUTUS_DATA_SUCCESS = "FETCH_ABOUTUS_DATA_SUCCESS";
export const FETCH_ABOUTUS_DATA_FAILURE = "FETCH_ABOUTUS_DATA_FAILURE";
// HISTORY
export const FETCH_HISTORY_DATA = "FETCH_HISTORY_DATA";
export const FETCH_HISTORY_DATA_SUCCESS = "FETCH_HISTORY_DATA_SUCCESS";
export const FETCH_HISTORY_DATA_FAILURE = "FETCH_HISTORY_DATA_FAILURE";
// AWARDS
export const FETCH_AWARD_DATA = "FETCH_AWARD_DATA";
export const FETCH_AWARD_DATA_SUCCESS = "FETCH_AWARD_DATA_SUCCESS";
export const FETCH_AWARD_DATA_FAILURE = "FETCH_AWARD_DATA_FAILURE";
// GIVING-BACK
export const FETCH_GIVINGBACK_DATA = "FETCH_GIVINGBACK_DATA";
export const FETCH_GIVINGBACK_DATA_SUCCESS = "FETCH_GIVINGBACK_DATA_SUCCESS";
export const FETCH_GIVINGBACK_DATA_FAILURE = "FETCH_GIVINGBACK_DATA_FAILURE";

// Reward view
export const FETCH_REWARD_DATA = "FETCH_REWARD_DATA";
export const FETCH_REWARD_DATA_SUCCESS = "FETCH_REWARD_DATA_SUCCESS";
export const FETCH_REWARD_DATA_FAILURE = "FETCH_REWARD_DATA_FAILURE";

// Search products
export const FETCH_SEARCH_RESULT_LIST = "FETCH_SEARCH_PRODUCTS";
export const FETCH_SEARCH_RESULT_LIST_SUCCESS = "FETCH_SEARCH_PRODUCTS_SUCCESS";
export const FETCH_SEARCH_RESULT_LIST_FAILURE = "FETCH_SEARCH_PRODUCTS_FAILURE";

export const UPDATE_SEARCH_TEXT = "UPDATE_SEARCH_TEXT";
export const UPDATE_SEARCH_TYPE = "UPDATE_SEARCH_TYPE";
export const REMOVE_SEARCH_TAG = "REMOVE_SEARCH_TAG";

export const RESET_FILTER_SIDEBAR = "RESET_FILTER_SIDEBAR";

//Weekly ad view
export const FETCH_WEEKLYAD_DATA = "FETCH_WEEKLYAD_DATA";
export const FETCH_WEEKLYAD_DATA_SUCCESS = "FETCH_WEEKLYAD_DATA_SUCCESS";
export const FETCH_WEEKLYAD_DATA_FAILURE = "FETCH_WEEKLYAD_DATA_FAILURE";

//Weekly ad gallery view
export const FETCH_WEEKLYADGALLERY_DATA = "FETCH_WEEKLYADGALLERY_DATA";
export const FETCH_WEEKLYADGALLERY_DATA_SUCCESS =
  "FETCH_WEEKLYADGALLERY_DATA_SUCCESS";
export const FETCH_WEEKLYADGALLERY_DATA_FAILURE =
  "FETCH_WEEKLYADGALLERY_DATA_FAILURE";

// Contact reason list
export const FETCH_CONTACT_REASON_LIST = "FETCH_CONTACT_REASON_LIST";
export const FETCH_CONTACT_REASON_LIST_SUCCESS =
  "FETCH_CONTACT_REASON_LIST_SUCCESS";
export const FETCH_CONTACT_REASON_LIST_FAILURE =
  "FETCH_CONTACT_REASON_LIST_FAILURE";

// Contact reason list
export const FETCH_PRODUCT_DETAILS = "FETCH_PRODUCT_DETAILS";
export const FETCH_PRODUCT_DETAILS_SUCCESS = "FETCH_PRODUCT_DETAILS_SUCCESS";
export const FETCH_PRODUCT_DETAILS_FAILURE = "FETCH_PRODUCT_DETAILS_FAILURE";

// Related products list
export const FETCH_RELATED_PRODUCT_LIST = "FETCH_RELATED_PRODUCT_LIST";
export const FETCH_RELATED_PRODUCT_LIST_SUCCESS =
  "FETCH_RELATED_PRODUCT_LIST_SUCCESS";
export const FETCH_RELATED_PRODUCT_LIST_FAILURE =
  "FETCH_RELATED_PRODUCT_LIST_FAILURE";

//feedback form
export const FETCH_FEEDBACK_DETAILS = "FETCH_FEEDBACK_DETAILS";
export const FETCH_FEEDBACK_DETAILS_SUCCESS = "FETCH_FEEDBACK_DETAILS_SUCCESS";
export const FETCH_FEEDBACK_DETAILS_FAILURE = "FETCH_FEEDBACK_DETAILS_FAILURE";

//fetch cart list
export const FETCH_CART_LIST = "FETCH_CART_LIST";
export const FETCH_CART_LIST_SUCCESS = "FETCH_CART_LIST_SUCCESS";
export const FETCH_CART_LIST_FAILURE = "FETCH_CART_LIST_FAILURE";

// add to cart list
export const ADD_PRODUCT_TO_CART_LIST = "ADD_PRODUCT_TO_CART_LIST";
export const ADD_PRODUCT_TO_CART_LIST_SUCCESS =
  "ADD_PRODUCT_TO_CART_LIST_SUCCESS";
export const ADD_PRODUCT_TO_CART_LIST_FAILURE =
  "ADD_PRODUCT_TO_CART_LIST_FAILURE";

// remove to cart list
export const REMOVE_PRODUCT_TO_CART_LIST = "REMOVE_PRODUCT_TO_CART_LIST";
export const REMOVE_PRODUCT_TO_CART_LIST_SUCCESS =
  "REMOVE_PRODUCT_TO_CART_LIST_SUCCESS";
export const REMOVE_PRODUCT_TO_CART_LIST_FAILURE =
  "REMOVE_PRODUCT_TO_CART_LIST_FAILURE";

// edit to cart list
export const UPDATE_PRODUCT_TO_CART_LIST = "UPDATE_PRODUCT_TO_CART_LIST";
export const UPDATE_PRODUCT_TO_CART_LIST_SUCCESS =
  "UPDATE_PRODUCT_TO_CART_LIST_SUCCESS";
export const UPDATE_PRODUCT_TO_CART_LIST_FAILURE =
  "UPDATE_PRODUCT_TO_CART_LIST_FAILURE";

//contactservices list
export const FETCH_CONTACTSERVICES_DETAILS = "FETCH_CONTACTSERVICES_DETAILS";
export const FETCH_CONTACTSERVICES_DETAILS_SUCCESS =
  "FETCH_CONTACTSERVICES_DETAILS_SUCCESS";
export const FETCH_CONTACTSERVICES_DETAILS_FAILURE =
  "FETCH_CONTACTSERVICES_DETAILS_FAILURE";

// clear  cart list
export const CLEAR_CART_LIST = "CLEAR_CART_LIST";
export const CLEAR_CART_LIST_SUCCESS = "CLEAR_CART_LIST_SUCCESS";
export const CLEAR_CART_LIST_FAILURE = "CLEAR_CART_LIST_FAILURE";

//Coupondepartment view
export const FETCH_COUPONDEPARTMENT_DATA = "FETCH_COUPONDEPARTMENT_DATA";
export const FETCH_COUPONDEPARTMENT_SUCCESS = "FETCH_COUPONDEPARTMENT_SUCCESS";
export const FETCH_COUPONDEPARTMENT_FAILURE = "FETCH_COUPONDEPARTMENT_FAILURE";

//Coupondepartment services view
export const FETCH_COUPON_DEPARTMENT_SERVICES_DATA =
  "FETCH_COUPON_DEPARTMENT_SERVICES_DATA";
export const FETCH_COUPON_DEPARTMENT_SERVICES_SUCCESS =
  "FETCH_COUPON_DEPARTMENT_SERVICES_SUCCESS";
export const FETCH_COUPON_DEPARTMENT_SERVICES_FAILURE =
  "FETCH_COUPON_DEPARTMENT_SERVICES_FAILURE";

// Customer address add
export const ADD_CUSTOMER_ADDRESS = "ADD_CUSTOMER_ADDRESS";
export const ADD_CUSTOMER_ADDRESS_SUCCESS = "ADD_CUSTOMER_ADDRESS_SUCCESS";
export const ADD_CUSTOMER_ADDRESS_FAILURE = "ADD_CUSTOMER_ADDRESS_FAILURE";

// Customer address list
export const FETCH_CUSTOMER_ADDRESS_LIST = "FETCH_CUSTOMER_ADDRESS_LIST";
export const FETCH_CUSTOMER_ADDRESS_LIST_SUCCESS =
  "FETCH_CUSTOMER_ADDRESS_LIST_SUCCESS";
export const FETCH_CUSTOMER_ADDRESS_LIST_FAILURE =
  "FETCH_CUSTOMER_ADDRESS_LIST_FAILURE";

export const SELECT_CUSTOMER_ADDRESS = "SELECT_CUSTOMER_ADDRESS";

// fetch store details
export const FETCH_STORE_DETAILS = "FETCH_STORE_DETAILS";
export const FETCH_STORE_DETAILS_SUCCESS = "FETCH_STORE_DETAILS_SUCCESS";
export const FETCH_STORE_DETAILS_FAILURE = "FETCH_STORE_DETAILS_FAILURE";

// select checkout store type
export const SELECT_CHECKOUT_STORE_TYPE = "SELECT_CHECKOUT_STORE_TYPE";
export const SELECT_CHECKOUT_PAYMENT_METHOD = "SELECT_CHECKOUT_PAYMENT_METHOD";

// fetch store time slots
export const FETCH_STORE_TIME_SLOTS = "FETCH_STORE_TIME_SLOTS";
export const FETCH_STORE_TIME_SLOTS_SUCCESS = "FETCH_STORE_TIME_SLOTS_SUCCESS";
export const FETCH_STORE_TIME_SLOTS_FAILURE = "FETCH_STORE_TIME_SLOTS_FAILURE";

export const SELECT_CHECKOUT_TIME_SLOT = "SELECT_CHECKOUT_TIME_SLOT";

// fetch cart Amount
export const FETCH_CART_AMOUNT_DETAILS = "FETCH_CART_AMOUNT_DETAILS";
export const FETCH_CART_AMOUNT_DETAILS_SUCCESS =
  "FETCH_CART_AMOUNT_DETAILS_SUCCESS";
export const FETCH_CART_AMOUNT_DETAILS_FAILURE =
  "FETCH_CART_AMOUNT_DETAILS_FAILURE";
//Mobile app view
export const FETCH_MOBILEAPP_DETAILS = "FETCH_MOBILEAPP_DETAILS";
export const FETCH_MOBILEAPP_DETAILS_SUCCESS =
  "FETCH_MOBILEAPP_DETAILS_SUCCESS";
export const FETCH_MOBILEAPP_DETAILS_FAILURE =
  "FETCH_MOBILEAPP_DETAILS_FAILURE";

// save place order
export const SAVE_PLACE_ORDER = "SAVE_PLACE_ORDER";
export const SAVE_PLACE_ORDER_SUCCESS = "SAVE_PLACE_ORDER_SUCCESS";
export const SAVE_PLACE_ORDER_FAILURE = "SAVE_PLACE_ORDER_FAILURE";

export const CHECKOUT_SPECIAL_INSTRUCTION = "CHECKOUT_SPECIAL_INSTRUCTION";

export const CHECKOUT_ORDER_PERFERRED_CONTACT_TYPE =
  "CHECKOUT_ORDER_PERFERRED_CONTACT_TYPE";

export const CHECKOUT_ORDER_PERFERRED_CONTACT =
  "CHECKOUT_ORDER_PERFERRED_CONTACT";

//Coupon List View
export const FETCH_COUPONS_LIST_DATA = "FETCH_COUPONS_LIST_DATA";
export const FETCH_COUPONS_LIST_DATA_SUCCESS =
  "FETCH_COUPONS_LIST_DATA_SUCCESS";
export const FETCH_COUPONS_LIST_DATA_FAILURE =
  "FETCH_COUPONS_LIST_DATA_FAILURE";

//Coupon Categories Sidebar View
export const FETCH_COUPONS_CATEGORIES_DATA = "FETCH_COUPONS_CATEGORIES_DATA";
export const FETCH_COUPONS_CATEGORIES_SUCCESS =
  "FETCH_COUPONS_CATEGORIES_SUCCESS";
export const FETCH_COUPONS_CATEGORIES_FAILURE =
  "FETCH_COUPONS_CATEGORIES_FAILURE";
export const UPDATE_COUPONS_CATEGORIES_LIST = "UPDATE_COUPONS_CATEGORIES_LIST";

//Coupon Add to Cart
export const ADD_COUPON_PRODUCT_TO_CART_LIST =
  "ADD_COUPON_PRODUCT_TO_CART_LIST";
export const ADD_COUPON_PRODUCT_TO_CART_LIST_SUCCESS =
  "ADD_COUPON_PRODUCT_TO_CART_LIST_SUCCESS";
export const ADD_COUPON_PRODUCT_TO_CART_LIST_FAILURE =
  "ADD_COUPON_PRODUCT_TO_CART_LIST_FAILURE";

//Coupon Fetch List
export const FETCH_COUPON_CART_LIST = "FETCH_COUPON_CART_LIST";
export const FETCH_COUPON_CART_LIST_SUCCESS = "FETCH_COUPON_CART_LIST_SUCCESS";
export const FETCH_COUPON_CART_LIST_FAILURE = "FETCH_COUPON_CART_LIST_FAILURE";

// remove coupon cart list
export const REMOVE_COUPON_TO_CART_LIST = "REMOVE_COUPON_TO_CART_LIST";
export const REMOVE_COUPON_TO_CART_LIST_SUCCESS =
  "REMOVE_COUPON_TO_CART_LIST_SUCCESS";
export const REMOVE_COUPON_TO_CART_LIST_FAILURE =
  "REMOVE_COUPON_TO_CART_LIST_FAILURE";

// Fetch Available coupons
export const FETCH_AVAILABLE_COUPONS = "FETCH_AVAILABLE_COUPONS";
export const FETCH_AVAILABLE_COUPONS_SUCCESS =
  "FETCH_AVAILABLE_COUPONS_SUCCESS";
export const FETCH_AVAILABLE_COUPONS_FAILURE =
  "FETCH_AVAILABLE_COUPONS_SUCCESS";

// Apply Available coupons
export const APPLY_AVAILABLE_COUPONS = "APPLY_AVAILABLE_COUPONS";
export const APPLY_AVAILABLE_COUPONS_SUCCESS =
  "APPLY_AVAILABLE_COUPONS_SUCCESS";
export const APPLY_AVAILABLE_COUPONS_FAILURE =
  "APPLY_AVAILABLE_COUPONS_FAILURE";
//Footer help view
export const FETCH_FOOTER_HELP_DETAILS = "FETCH_FOOTER_HELP_DETAILS";
export const FETCH_FOOTER_HELP_DETAILS_SUCCESS =
  "FETCH_FOOTER_HELP_DETAILS_SUCCESS";
export const FETCH_FOOTER_HELP_DETAILS_FAILURE =
  "FETCH_FOOTER_HELP_DETAILS_FAILURE";

//Feature Product Fetch List
export const FETCH_FEATURE_PRODUCT_LIST = "FETCH_FEATURE_PRODUCT_LIST";
export const FETCH_FEATURE_PRODUCT_LIST_SUCCESS =
  "FETCH_FEATURE_PRODUCT_LIST_SUCCESS";
export const FETCH_FEATURE_PRODUCT_LIST_FAILURE =
  "FETCH_FEATURE_PRODUCT_LIST_FAILURE";

//Jobs Fetch List
export const FETCH_JOBS_LIST = "FETCH_JOBS_LIST";
export const FETCH_JOBS_LIST_SUCCESS = "FETCH_JOBS_LIST_SUCCESS";
export const FETCH_JOBS_LIST_FAILURE = "FETCH_JOBS_LIST_FAILURE";

//Fetch client details
export const FETCH_CLIENT_DETAILS = "FETCH_CLIENT_DETAILS";
export const FETCH_CLIENT_DETAILS_SUCCESS = "FETCH_CLIENT_DETAILS_SUCCESS";
export const FETCH_CLIENT_DETAILS_FAILURE = "FETCH_CLIENT_DETAILS_FAILURE";

//Employment details
export const FETCH_EMPLOYMENT_DETAILS = "FETCH_EMPLOYMENT_DETAILS";
export const FETCH_EMPLOYMENT_DETAILS_SUCCESS =
  "FETCH_EMPLOYMENT_DETAILS_SUCCESS";
export const FETCH_EMPLOYMENT_DETAILS_FAILURE =
  "FETCH_EMPLOYMENT_DETAILS_FAILURE";

// Fetch homepage coupons
export const FETCH_HOME_PAGE_COUPONS_DATA = "FETCH_HOME_PAGE_COUPONS_DATA";
export const FETCH_HOME_PAGE_COUPONS_DATA_SUCCESS =
  "FETCH_HOME_PAGE_COUPONS_DATA_SUCCESS";
export const FETCH_HOME_PAGE_COUPONS_DATA_FAILURE =
  "FETCH_HOME_PAGE_COUPONS_DATA_FAILURE";

//Update customer details
export const UPDATE_CUSTOMER_PROFILE_DETAILS =
  "UPDATE_CUSTOMER_PROFILE_DETAILS";
export const UPDATE_CUSTOMER_PROFILE_SUCCESS =
  "UPDATE_CUSTOMER_PROFILE_SUCCESS";
export const UPDATE_CUSTOMER_PROFILE_FAILURE =
  "UPDATE_CUSTOMER_PROFILE_FAILURE";

//Update customer password details
export const UPDATE_CUSTOMER_PIN_DETAILS = "UPDATE_CUSTOMER_PIN_DETAILS";
export const UPDATE_CUSTOMER_PIN_SUCCESS = "UPDATE_CUSTOMER_PIN_SUCCESS";
export const UPDATE_CUSTOMER_PIN_FAILURE = "UPDATE_CUSTOMER_PIN_FAILURE";

//Customer Order details
export const FETCH_ORDERS_DATA = "FETCH_ORDERS_DATA";
export const FETCH_ORDERS_DATA_SUCCESS = "FETCH_ORDERS_DATA_SUCCESS";
export const FETCH_ORDERS_DATA_FAILURE = "FETCH_ORDERS_DATA_FAILURE";

//catering and mealkit customer Order data
export const FETCH_CATERING_MEALS_ORDERS_DATA =
  "FETCH_CATERING_MEALS_ORDERS_DATA";
export const FETCH_CATERING_MEALS_ORDERS_DATA_SUCCESS =
  "FETCH_CATERING_MEALS_ORDERS_DATA_SUCCESS";
export const FETCH_CATERING_MEALS_ORDERS_DATA_FAILURE =
  "FETCH_CATERING_MEALS_ORDERS_DATA_FAILURE";

//Customer Wishlist details
export const FETCH_WISHLIST_DATA = "FETCH_WISHLIST_DATA";
export const FETCH_WISHLIST_DATA_SUCCESS = "FETCH_WISHLIST_DATA_SUCCESS";
export const FETCH_WISHLIST_DATA_FAILURE = "FETCH_WISHLIST_DATA_FAILURE";

//Add Wishlist products
export const ADD_WISHLIST_DATA = "ADD_WISHLIST_DATA";
export const ADD_WISHLIST_DATA_SUCCESS = "ADD_WISHLIST_DATA_SUCCESS";
export const ADD_WISHLIST_DATA_FAILURE = "ADD_WISHLIST_DATA_FAILURE";

// remove address list
export const REMOVE_CUSTOMER_ADDRESS = "REMOVE_CUSTOMER_ADDRESS";
export const REMOVE_CUSTOMER_ADDRESS_SUCCESS =
  "REMOVE_CUSTOMER_ADDRESS_SUCCESS";
export const REMOVE_CUSTOMER_ADDRESS_FAILURE =
  "REMOVE_CUSTOMER_ADDRESS_FAILURE";

// forgot password
export const CUSTOMER_FORGOT_PASSWORD = "CUSTOMER_FORGOT_PASSWORD";
export const CUSTOMER_FORGOT_PASSWORD_SUCCESS =
  "CUSTOMER_FORGOT_PASSWORD_SUCCESS";
export const CUSTOMER_FORGOT_PASSWORD_FAILURE =
  "CUSTOMER_FORGOT_PASSWORD_FAILURE";

// customer address list
export const FETCH_CUSTOMER_PROFILE_DETAILS = "FETCH_CUSTOMER_PROFILE_DETAILS";
export const FETCH_CUSTOMER_PROFILE_SUCCESS = "FETCH_CUSTOMER_PROFILE_SUCCESS";
export const FETCH_CUSTOMER_PROFILE_FAILURE = "FETCH_CUSTOMER_PROFILE_FAILURE";

//Add Custom List
export const ADD_LIST = "ADD_LIST";
export const ADD_LIST_SUCCESS = "ADD_LIST_SUCCESS";
export const ADD_LIST_FAILURE = "ADD_LIST_FAILURE";
// Remove List
export const REMOVE_LIST = "REMOVE_LIST";
export const REMOVE_LIST_SUCCESS = "REMOVE_LIST_SUCCESS";
export const REMOVE_LIST_FAILURE = "REMOVE_LIST_FAILURE";
// Remove Product From List
export const REMOVE_LIST_ITEM = "REMOVE_LIST_ITEM";
export const REMOVE_LIST_ITEM_SUCCESS = "REMOVE_LIST_ITEM_SUCCESS";
export const REMOVE_LIST_ITEM_FAILURE = "REMOVE_LIST_ITEM_FAILURE";
// GET LISTS
export const FETCH_LIST = "FETCH_LIST";
export const FETCH_LIST_SUCCESS = "FETCH_LIST_SUCCESS";
export const FETCH_LIST_FAILURE = "FETCH_LIST_FAILURE";
// GET LIST DATA
export const FETCH_LIST_DATA = "FETCH_LIST_DATA";
export const FETCH_LIST_DATA_SUCCESS = "FETCH_LIST_DATA_SUCCESS";
export const FETCH_LIST_DATA_FAILURE = "FETCH_LIST_DATA_FAILURE";
// LIST STATUS TOGGLE
export const UPDATE_LIST_STATUS = "UPDATE_LIST_STATUS";
export const UPDATE_LIST_STATUS_SUCCESS = "UPDATE_LIST_STATUS_SUCCESS";
export const UPDATE_LIST_STATUS_FAILURE = "UPDATE_LIST_STATUS_FAILURE";
//ORDER DETAILS
export const FETCH_ORDER_DETAILS = "FETCH_ORDER_DETAILS";
export const FETCH_ORDER_DETAILS_SUCCESS = "FETCH_ORDER_DETAILS_SUCCESS";
export const FETCH_ORDER_DETAILS_FAILURE = "FETCH_ORDER_DETAILS_FAILURE";
//ADD and add all items to cart
export const ADD_OR_ADD_ALL_TO_CART_PRODUCT = "ADD_OR_ADD_ALL_TO_CART_PRODUCT";
export const ADD_OR_ADD_ALL_TO_CART_PRODUCT_SUCCESS =
  "ADD_OR_ADD_ALL_TO_CART_PRODUCT_SUCCESS";
export const ADD_OR_ADD_ALL_TO_CART_PRODUCT_FAILURE =
  "ADD_OR_ADD_ALL_TO_CART_PRODUCT_FAILURE";
//mark uncompleted and uncomplete all
export const MARK_UNCOMPLETED_PRODUCT = "MARK_UNCOMPLETED_PRODUCT";
export const MARK_UNCOMPLETED_PRODUCT_SUCCESS =
  "MARK_UNCOMPLETED_PRODUCT_SUCCESS";
export const MARK_UNCOMPLETED_PRODUCT_FAILURE =
  "MARK_UNCOMPLETED_PRODUCT_FAILURE";
//mark complete and complete all
export const MARK_COMPLETE_ALL_PRODUCT = "MARK_COMPLETE_ALL_PRODUCT";
export const MARK_COMPLETE_ALL_PRODUCT_SUCCESS =
  "MARK_COMPLETE_ALL_PRODUCT_SUCCESS";
export const MARK_COMPLETE_ALL_PRODUCT_FAILURE =
  "MARK_COMPLETE_ALL_PRODUCT_FAILURE";
//Add Donation Form
export const FETCH_DONATION_LIST = "FETCH_DONATION_LIST";
export const FETCH_DONATION_LIST_SUCCESS = "FETCH_DONATION_LIST_SUCCESS";
export const FETCH_DONATION_LIST_FAILURE = "FETCH_DONATION_LIST_FAILURE";

export const RESET_SEARCH = "RESET_SEARCH";

export const FETCH_BLOG_LIST = "FETCH_BLOG_LIST";
export const FETCH_BLOG_LIST_SUCCESS = "FETCH_BLOG_LIST_SUCCESS";
export const FETCH_BLOG_LIST_FAILURE = "FETCH_BLOG_LIST_FAILURE";

//Campaign product  List
export const FETCH_CAMPAIGN_PRODUCT_LIST = "FETCH_CAMPAIGN_PRODUCT_LIST";
export const FETCH_CAMPAIGN_PRODUCT_LIST_SUCCESS =
  "FETCH_CAMPAIGN_PRODUCT_LIST_SUCCESS";
export const FETCH_CAMPAIGN_PRODUCT_LIST_FAILURE =
  "FETCH_CAMPAIGN_PRODUCT_LIST_FAILURE";

//Home page Ads  List
export const FETCH_HOME_ADS_LIST = "FETCH_HOME_ADS_LIST";
export const FETCH_HOME_ADS_LIST_SUCCESS = "FETCH_HOME_ADS_LIST_SUCCESS";
export const FETCH_HOME_ADS_LIST_FAILURE = "FETCH_HOME_ADS_LIST_FAILURE";

//Store Details  List
export const FETCH_STORE_HISTORY_LIST = "FETCH_STORE_HISTORY_LIST";
export const FETCH_STORE_HISTORY_LIST_SUCCESS =
  "FETCH_STORE_HISTORY_LIST_SUCCESS";
export const FETCH_STORE_HISTORY_LIST_FAILURE =
  "FETCH_STORE_HISTORY_LIST_FAILURE";

export const FETCH_CATERING_DEPARTMENT_TREE = "FETCH_CATERING_DEPARTMENT_TREE";
export const FETCH_CATERING_DEPARTMENT_TREE_SUCCESS =
  "FETCH_CATERING_DEPARTMENT_TREE_SUCCESS";
export const FETCH_CATERING_DEPARTMENT_TREE_FAILURE =
  "FETCH_CATERING_DEPARTMENT_TREE_FAILURE";

export const FETCH_CATERING_PRODUCT_LIST = "FETCH_CATERING_DEPARTMENT";
export const FETCH_CATERING_PRODUCT_LIST_SUCCESS =
  "FETCH_CATERING_DEPARTMENT_SUCCESS";
export const FETCH_CATERING_PRODUCT_LIST_FAILURE =
  "FETCH_CATERING_DEPARTMENT_FAILURE";

export const UPDATE_CATERING_AND_MEAL_CUSTOM_OPTION =
  "UPDATE_CATERING_AND_MEAL_CUSTOM_OPTION";

export const RESET_SELECTED_CUSTOM_OPTIONS = "RESET_SELECTED_CUSTOM_OPTIONS";

export const ADD_CATERING_AND_MEAL_KIT_PRODUCT_TO_CART_LIST =
  "ADD_CATERING_AND_MEAL_KIT_PRODUCT_TO_CART_LIST";
export const ADD_CATERING_AND_MEAL_KIT_PRODUCT_TO_CART_LIST_SUCCESS =
  "ADD_CATERING_AND_MEAL_KIT_PRODUCT_TO_CART_LIST_SUCCESS";
export const ADD_CATERING_AND_MEAL_KIT_PRODUCT_TO_CART_LIST_FAILURE =
  "ADD_CATERING_AND_MEAL_KIT_PRODUCT_TO_CART_LIST_FAILURE";
export const RESET_ADD_CATERING_AND_MEAL_KIT_PRODUCT_TO_CART_ERROR =
  "RESET_ADD_CATERING_AND_MEAL_KIT_PRODUCT_TO_CART_ERROR";

export const UPDATE_CATERING_AND_MEAL_KIT_PRODUCT_TO_CART_LIST =
  "UPDATE_CATERING_AND_MEAL_KIT_PRODUCT_TO_CART_LIST";
export const UPDATE_CATERING_AND_MEAL_KIT_PRODUCT_TO_CART_LIST_SUCCESS =
  "UPDATE_CATERING_AND_MEAL_KIT_PRODUCT_TO_CART_LIST_SUCCESS";
export const UPDATE_CATERING_AND_MEAL_KIT_PRODUCT_TO_CART_LIST_FAILURE =
  "UPDATE_CATERING_AND_MEAL_KIT_PRODUCT_TO_CART_LIST_FAILURE";

//Remove catering product item
export const REMOVE_CATERING_PRODUCT_TO_CART_LIST =
  "REMOVE_CATERING_PRODUCT_TO_CART_LIST";
export const REMOVE_CATERING_PRODUCT_TO_CART_LIST_SUCCESS =
  "REMOVE_CATERING_PRODUCT_TO_CART_LIST_SUCCESS";
export const REMOVE_CATERING_PRODUCT_TO_CART_LIST_FAILURE =
  "REMOVE_CATERING_PRODUCT_TO_CART_LIST_FAILURE";

// Clear catering cart list
export const CLEAR_CATERING_CART_LIST = "CLEAR_CATERING_CART_LIST";
export const CLEAR_CATERING_CART_LIST_SUCCESS =
  "CLEAR_CATERING_CART_LIST_SUCCESS";
export const CLEAR_CATERING_CART_LIST_FAILURE =
  "CLEAR_CATERING_CART_LIST_FAILURE";

export const UPDATE_CATERING_AND_MEAL_CART_CUSTOM_OPTION =
  "UPDATE_CATERING_AND_MEAL_CART_CUSTOM_OPTION";
export const RESET_SELECTED_CART_CUSTOM_OPTIONS =
  "RESET_SELECTED_CART_CUSTOM_OPTIONS";

export const SELECT_PICKUP_TYPE = "SELECT_PICKUP_TYPE";

export const SAVE_PLACE_ORDER_CATERING_MEAL = "SAVE_PLACE_ORDER_CATERING_MEAL";
export const SAVE_PLACE_ORDER_CATERING_MEAL_SUCCESS =
  "SAVE_PLACE_ORDER_CATERING_MEAL_SUCCESS";
export const SAVE_PLACE_ORDER_CATERING_MEAL_FAILURE =
  "SAVE_PLACE_ORDER_CATERING_MEAL_FAILURE";

//recipes
export const FETCH_RECIPES_MASTER_DATA = "FETCH_RECIPES_MASTER_DATA";
export const FETCH_RECIPES_MASTER_DATA_SUCCESS =
  "FETCH_RECIPES_MASTER_DATA_SUCCESS";
export const FETCH_RECIPES_MASTER_DATA_FAILURE =
  "FETCH_RECIPES_MASTER_DATA_FAILURE";

export const FETCH_RECIPES_LIST = "FETCH_RECIPES_LIST";
export const FETCH_RECIPES_LIST_SUCCESS = "FETCH_RECIPES_LIST_SUCCESS";
export const FETCH_RECIPES_LIST_FAILURE = "FETCH_RECIPES_LIST_FAILURE";

//recipe detail
export const FETCH_RECIPE_DETAIL = "FETCH_RECIPE_DETAIL";
export const FETCH_RECIPE_DETAIL_SUCCESS = "FETCH_RECIPE_DETAIL_SUCCESS";
export const FETCH_RECIPE_DETAIL_FAILURE = "FETCH_RECIPE_DETAIL_FAILURE";

export const FETCH_RECIPES_PRODUCT_LIST = "FETCH_RECIPES_PRODUCT_LIST";
export const FETCH_RECIPES_PRODUCT_LIST_SUCCESS =
  "FETCH_RECIPES_PRODUCT_LIST_SUCCESS";
export const FETCH_RECIPES_PRODUCT_LIST_FAILURE =
  "FETCH_RECIPES_PRODUCT_LIST_FAILURE";

//fetch similar recipes
export const FETCH_SIMILAR_RECIPE_LIST = "FETCH_SIMILAR_RECIPE_LIST";
export const FETCH_SIMILAR_RECIPE_LIST_SUCCESS =
  "FETCH_SIMILAR_RECIPE_LIST_SUCCESS";
export const FETCH_SIMILAR_RECIPE_LIST_FAILURE =
  "FETCH_SIMILAR_RECIPE_LIST_FAILURE";

export const FILTER_RECIPE_SEARCH_TEXT = "FILTER_RECIPE_SEARCH_TEXT";

export const CANCEL_ORDER_BY_CUSTOMER = "CANCEL_ORDER_BY_CUSTOMER";
export const CANCEL_ORDER_BY_CUSTOMER_SUCCESS =
  "CANCEL_ORDER_BY_CUSTOMER_SUCCESS";
export const CANCEL_ORDER_BY_CUSTOMER_FAILURE =
  "CANCEL_ORDER_BY_CUSTOMER_FAILURE";

export const FETCH_REDEEM_LIST = "FETCH_REDEEM_LIST";
export const FETCH_REDEEM_LIST_SUCCESS = "FETCH_REDEEM_LIST_SUCCESS";
export const FETCH_REDEEM_LIST_FAILURE = "FETCH_REDEEM_LIST_FAILURE";

export const SET_REDEEM_OPTION = "SET_REDEEM_OPTION";
export const SET_REDEEM_OPTION_SUCCESS = "SET_REDEEM_OPTION_SUCCESS";
export const SET_REDEEM_OPTION_FAILURE = "SET_REDEEM_OPTION_FAILURE";

// add product to list

export const ADD_PRODUCT_TO_LIST = "ADD_PRODUCT_TO_LIST";
export const ADD_PRODUCT_TO_LIST_SUCCESS = "ADD_PRODUCT_TO_LIST_SUCCESS";
export const ADD_PRODUCT_TO_LIST_FAILURE = "ADD_PRODUCT_TO_LIST_FAILURE";

// save ebt response
export const SAVE_EBT_PAYMENT_RESPONSE = "SAVE_EBT_PAYMENT_RESPONSE";
export const SAVE_EBT_PAYMENT_PAID_LIST = "SAVE_EBT_PAYMENT_PAID_LIST";

//CANCEL EBT PAYMENT

export const CANCEL_EBT_PAYMENT = "CANCEL_EBT_PAYMENT";
export const CANCEL_EBT_PAYMENT_SUCCESS = "CANCEL_EBT_PAYMENT_SUCCESS";
export const CANCEL_EBT_PAYMENT_FAILURE = "CANCEL_EBT_PAYMENT_FAILURE";

export const RESET_EBT_CHECKOUT_STATE = "RESET_EBT_CHECKOUT_STATE";
export const SAVE_CURRENT_DEPARTMENT = "SAVE_CURRENT_DEPARTMENT";


//get order details
export const FETCH_ORDER_DETAIL = "FETCH_ORDER_DETAIL";
export const FETCH_ORDER_DETAIL_SUCCESS = "FETCH_ORDER_DETAIL_SUCCESS";
export const FETCH_ORDER_DETAIL_FAILURE = "FETCH_ORDER_DETAIL_FAILURE";

export const ADD_MULTIPLE_PRODUCT_TO_LIST = "ADD_MULTIPLE_PRODUCT_TO_LIST";
export const ADD_MULTIPLE_PRODUCT_TO_LIST_SUCCESS = "ADD_MULTIPLE_PRODUCT_TO_LIST_SUCCESS";
export const ADD_MULTIPLE_PRODUCT_TO_LIST_FAILURE = "ADD_MULTIPLE_PRODUCT_TO_LIST_FAILURE";

//fetch ebt card info
export const FETCH_EBT_CARD_INFORMATION = "FETCH_EBT_CARD_INFORMATION";
export const FETCH_EBT_CARD_INFORMATION_SUCCESS = "FETCH_EBT_CARD_INFORMATION_SUCCESS";
export const FETCH_EBT_CARD_INFORMATION_FAILURE = "FETCH_EBT_CARD_INFORMATION_FAILURE";

export const SAVE_PAYMENT_TRANSACTION_ID = "SAVE_PAYMENT_TRANSACTION_ID";
export const SAVE_PAYMENT_TRANSACTION_TYPE = "SAVE_PAYMENT_TRANSACTION_TYPE";

//Analytics product  Track
export const ANALYTICS_PRODUCT_TRACK = "ANALYTICS_PRODUCT_TRACK";
export const ANALYTICS_PRODUCT_TRACK_SUCCESS = "ANALYTICS_PRODUCT_TRACK_SUCCESS";
export const ANALYTICS_PRODUCT_TRACK_FAILURE = "ANALYTICS_PRODUCT_TRACK_FAILURE";

export const ANALYTICS_PRODUCT_SEARCH = "ANALYTICS_PRODUCT_SEARCH";
export const ANALYTICS_PRODUCT_SEARCH_SUCCESS = "ANALYTICS_PRODUCT_SEARCH_SUCCESS";
export const ANALYTICS_PRODUCT_SEARCH_FAILURE = "ANALYTICS_PRODUCT_SEARCH_FAILURE";

export const FETCH_CUSTOM_PAGE_CONTENT = "FETCH_CUSTOM_PAGE_CONTENT";
export const FETCH_CUSTOM_PAGE_CONTENT_SUCCESS = "FETCH_CUSTOM_PAGE_CONTENT_SUCCESS";
export const FETCH_CUSTOM_PAGE_CONTENT_FAILURE = "FETCH_CUSTOM_PAGE_CONTENT_FAILURE";

export const DELETE_USER_ACCOUNT = "DELETE_USER_ACCOUNT";
export const DELETE_USER_ACCOUNT_SUCCESS = "DELETE_USER_ACCOUNT_SUCCESS";
export const DELETE_USER_ACCOUNT_FAILURE = "DELETE_USER_ACCOUNT_FAILURE";

export const CLEAR_REGISTER_MESSAGE = 'CLEAR_REGISTER_MESSAGE';
export const CLEAR_LOGIN_MESSAGE = 'CLEAR_LOGIN_MESSAGE';
export const CLEAR_MESSAGE_FOR_UPDATE_PROFILE = 'CLEAR_MESSAGE_FOR_UPDATE_PROFILE';
export const CLEAR_MESSAGE_FOR_CHANGE_PASSWORD = 'CLEAR_MESSAGE_FOR_CHANGE_PASSWORD';