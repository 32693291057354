import React, { useEffect } from "react";
import BreadcrumbDetailed from "components/Common/BreadcrumbDetailed";
import { useDispatch, useSelector } from "react-redux";
import { NextArrow, PrevArrow } from "components/Common/Arrows";
import Slider from "react-slick/lib/slider";
import AdsSpaceSection from "./AdsSpaceSection";
import BestSeller from "./BestSeller";
import { getFeatureproduct } from "redux/actions";
import ProductCard from "components/ProductCard";
import { getLocalStorageValue } from 'config/helper';

import "./feature-product.scss";
import loader from "assets/images/loader.gif";

const FeatureProduct = () => {
  const dispatch = useDispatch();
  const clientId = getLocalStorageValue("RSAclient-id");

  const { currentStoreID } = useSelector(({ store }) => store);
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");

  const breadcrumbList = [
    { lable: "Featured & Best Sellers", link: "", searchTag: "" },
  ];

  const featureproductData = useSelector(
    ({ featureProduct }) => featureProduct.featureproductData.lists
  );
  const departmentproductData = useSelector(
    ({ featureProduct }) =>
      featureProduct.featureproductData.department_product_list
  );
  const adsDetails = useSelector(
    ({ featureProduct }) =>
      featureProduct.featureproductData.ads_section
  );
  const featureproductDataLoading = useSelector(
    ({ featureProduct }) =>
      featureProduct.featureproductDataLoading
  );
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: Math.min(featureproductData?.products_list.length, 6),
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };
    dispatch(getFeatureproduct(body));
  }, [dispatch]); // eslint-disable-line
  return (
    <>
      {featureproductDataLoading && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img style={{ width: '100px', height: '50px' }} src={loader} />
          <span>Loading, please wait....</span>
        </div>
      )}
      {featureproductData && departmentproductData && (
        <BreadcrumbDetailed
          breadcrumbList={breadcrumbList}
          title={featureproductData.title} //Need to pass title through api res
        />
      )}

      <AdsSpaceSection adsDetails={adsDetails && adsDetails} />
      {featureproductData && departmentproductData && (
        <section className="bestseller-slider bestseller-slider-no-bottom-space">
          <div className="container">
            <div className="sellerpage-title">
              <p>{featureproductData.title}</p>
              <span>Products you have enjoyed</span>
            </div>
            <Slider {...settings}>
              {featureproductData.products_list.map((product, index) => {
                return <ProductCard product={product} key={index} />;
              })}
            </Slider>
          </div>
        </section>
      )}
      {departmentproductData && departmentproductData && (
        <BestSeller departmentproductData={departmentproductData} />
      )}
    </>
  );
};
export default FeatureProduct;
