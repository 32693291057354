import { useState, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";

import "./pdf_file_view.scss";

function PDFFileView({ section }) {
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess(e) {
    setNumPages(e._pdfInfo?.numPages);
  }

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
  }, []);

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  return (
    <>
      {section.image !== "" && (
        <div className="main-container">
          <div className="button-container">
            <button
              type="button"
              disabled={pageNumber <= 1}
              onClick={previousPage}
              className="Pre"
            >
              Previous
            </button>
            <button
              type="button"
              disabled={pageNumber >= numPages}
              onClick={nextPage}
            >
              Next
            </button>
            <span>
              Page {pageNumber || (numPages ? 1 : "--")} / {numPages || "--"}
            </span>
            {/* <button>+</button>
            <button>-</button>
            <span style={{ padding: "2rem" }}>Zoom 100%</span> */}
          </div>
          <div className="pdf-container">
            <div className="pdf-inner">
              <Document
                file="https://rsapdfflyers.s3.amazonaws.com/custome_pages_pdf_48_1713195272.pdf"
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Page pageNumber={pageNumber} />
              </Document>
            </div>
            <style>
              {`
                .react-pdf__Page__textContent {
                    display: none !important;
                }
                .react-pdf__Page__annotations{
                  display: none !important;
                }
            `}
            </style>
          </div>
          <div className="button-container">
            <button
              type="button"
              disabled={pageNumber <= 1}
              onClick={previousPage}
              className="Pre"
            >
              Previous
            </button>
            <button
              type="button"
              disabled={pageNumber >= numPages}
              onClick={nextPage}
            >
              Next
            </button>
            <span>
              Page {pageNumber || (numPages ? 1 : "--")} / {numPages || "--"}
            </span>
            {/* <button>+</button>
            <button>-</button>
            <span style={{ padding: "2rem" }}>Zoom 100%</span> */}
          </div>
        </div>
      )}
    </>
  );
}

export default PDFFileView;
